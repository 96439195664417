<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Partners'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="dialogCreatePartner = true">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table partners -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="partners"
        :totalItems="$store.state.partners.totalCountPartners"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getPartners"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/partners/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.country`]="{ item }">
          {{ item.country.name }}
        </template>
        <template v-slot:[`item.withholding_withdraws`]="{ item }"> {{ item.withholding_withdraws }}% </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create partner -->
    <DialogBox :model="dialogCreatePartner">
      <template slot="toolbar">
        <span>Crear partner</span>
      </template>
      <template slot="content">
        <v-form ref="formCreatePartner" v-model="formCreatePartnerValid">
          <v-text-field v-model="partner.name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field
            v-model.number="partner.withholding_withdraws"
            type="number"
            label="Retenciones"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <CountryAutocomplete :countryId="partner.country" :editable="true" @selected="partner.country = $event" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreatePartner', 'formCreatePartner')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Partners",
  components: {
    Banner,
    DataTable,
    DialogBox,
    CountryAutocomplete
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // Partners datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nombre", value: "name", sortable: false },
        { text: "País", value: "country", sortable: false },
        { text: "Retenciones", value: "withholding_withdraws", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Dialogs
      dialogCreatePartner: false,
      formCreatePartnerValid: false,

      // Fields of partner
      partner: {
        name: "",
        withholding_withdraws: 0,
        country: ""
      }
    };
  },
  computed: {
    ...mapState({
      partners: (state) => state.partners.partners,
      user: (state) => state.user.user,
      dbUser: (state) => state.user.dbUser
    }),
    _() {
      return _;
    }
  },
  async created() {
    if (this.user.role === "SUPERTECHNICIAN") {
      await this.getPartners({ size: 10, sort: { created_at: "DESC" }, filter: { id: this.dbUser.technician.partner_id } });
    } else await this.getPartners({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getPartners: "partners/getPartners"
    }),

    async handlePrevIcon(elasticData) {
      await this.getPartners(elasticData);
    },

    async handleNextIcon(elasticData) {
      await this.getPartners(elasticData);
    },

    async handleSearch(elasticData) {
      await this.getPartners(elasticData);
    },

    // Create partner
    async create() {
      if (this.$refs.formCreatePartner.validate()) {
        let partnerFormattedData = { ...this.partner };
        await this.$store.dispatch("partners/createPartner", partnerFormattedData);
        await this.getPartners({ size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreatePartner", "formCreatePartner");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.partner = this.$options.data().partner;
    }
  }
};
</script>
